

.ant-table-row.expanded-row > td {
    background-color: #e6e6e6; /* Change this to the color you want */
  }

  .expanded-table {
    background-color: #595959; /* Change this to the color you want */
  }
  
  .expanded-row-content {
    background-color: #595959; /* Change this to the color you want */
  }

  /* Change the height of the table header */
.ant-table-thead .ant-table-row {
    height: 15px !important; /* Set the desired height */
  }
  
  /* Make sure the table header cells are correctly sized */
  .ant-table-thead .ant-table-row .ant-table-cell {
    height: 15px !important; /* Set the desired height */
    line-height: 15px !important; /* Set the same value as the height to vertically align the text */
    padding: 0 8px !important; /* Remove padding top and bottom, keep left and right padding */
  }
  .audio-records-main-space {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 15px;
    /* align-items: center; */
  }
  .audio-records-filters-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
  }


  .audio-records-table-row {
    display: flex;
    min-width: 80%;
    /* padding: 20px; */
    height: 75vh;
    width: 100%;
  }

  .audio-records-table {
    /* display: flex; */
    min-width: 100% !important;
    height: 70vh;
    width: 100%;
  }

  .audio-records-column-player {
    max-width: 25px !important;
    min-width: 25px !important;
    width: 25px !important;
  }

  .table-audio-records-expandable .ant-table-row-expand-icon-cell {
    max-width: 10px !important;
    min-width: 10px !important;
    width: 10px !important;
  }

  .ant-table-row.active-row > td {
    background-color: #e6e6e6;
    animation-name: color;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

/* 
  @keyframes color {
    0% {
      background-color: #c1dcfb;
    }
    50% {
      background-color: #c1dcfb7e;
    }
    100% {
      background-color: #c1dcfb00;
    }
  } */