
  
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f0f2f5;
  }

  .login-spinner {
    margin: auto;
  }
  
  .login-form {
    width: 300px;
    max-width: 300px;
  }
  
  .login-form-item {
    width: 100%;
  }
  
  .login-form-button {
    width: 100px;
  }
  
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    width: 300px;
    min-width: 300px;
  }
  
  .logo-login {
    /* width: 300px; */
    min-width: 300px;
  }
  
  .logo-text {
    font-size: 16px;
    width: max-content;
  }