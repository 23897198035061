.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-inline-end: 8px;
  }
  
  .ant-upload-rtl.upload-list-inline .ant-upload-list-item {
    float: right;
  }

  .header-row {
    display: flex;
    align-items: center;
    /* padding-top: 1px; */
    margin-top: 0px;
  }

  .modal-ticket-page {
    top: 20px;
  }

  /* CustomTimeline.css */

.custom-timeline {
  padding-top: 34px;
}

.audio-record-text {
  text-align: center;
}