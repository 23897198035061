.logo {
    /* display: inline-block; */
    float: left;
    height: 31px;
    width: 31px;
    margin: 16px 24px 16px 0;
    /* vertical-align: middle; */
  }
.main-layout-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-layout-menu {
  width: 90%;
}

.main-layout-content {
  overflow: auto;
  /* height: calc(100vh - 64px); */
}

.language-selector {
  width: 140px;
  margin-bottom: 0;
  /* color: aliceblue; */

}

.flag-icon {
  width: 15px;
  border: 1px solid #d8d6d6;
}

.main-layout-avatar img{
  object-fit: contain;
  color: #61adff;
  font-size: 24px;
}


/* App.css */


.main-layout-language-radio .ant-radio-button-wrapper .ant-radio-button-wrapper-checked > span {
  accent-color: #509679 !important;
}


.ant-radio-button .ant-radio-button-checked .main-layout-language-radio {
  accent-color: #9b2d2daa !important;
}
